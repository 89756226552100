<template>
  <div>
    <Card :bordered="false" dis-hover>
        <p slot="title">订单概况</p>
        <div slot="extra" class="buttonList">
          <i-button  size="small" type="success" @click="handleSubmit">提交审批</i-button>
          <i-button  size="small" class="btn-black m-l-8" @click="submit(0)">保存并关闭</i-button>
        </div>
        <Row>
          <i-col span="12"><strong>客户：</strong> {{statementDetail.advertiserName}}</i-col>
          <i-col span="12"><strong>发布品牌：</strong> {{statementDetail.brandName}}</i-col>
        </Row>
        <Row>
          <i-col span="12"><strong>发布档期：</strong> {{statementDetail.schedule}}</i-col>
          <i-col span="12"><strong>订单备注：</strong> {{order.remark}}</i-col>
        </Row>
    </Card>

    <Card :bordered="false" dis-hover>
      <p slot="title">设置支付方式</p>
      <Row :gutter="10">
        <i-col span="12">
           <i-select v-model="currentDiscountId">
              <i-option v-for="(dis,i ) in discounts" :key="i" :value="dis.paydiscountId">
                {{dis.desc}}
              </i-option>
            </i-select>
        </i-col>
        <i-col span="12">
          <div>
            结算单发布费总金额：
            <s class="title">{{formatMoney(usePrice)}}</s>
            <span class="money">{{formatMoney(computedUsePrice ) }}  </span>
          </div>
        </i-col>
      </Row>
      <payment :items="payItems"></payment>
    </Card>

    <Card :bordered="false" dis-hover>
          <p slot="title">发布费汇总</p>
          <products
                    :products="products"
                    :versionId="statementVersionId"
                    @reloadProducts="getStatementForOrderInfo"
                  ></products>
        </Card>
        <Card :bordered="false" dis-hover>
          <p slot="title">服务费汇总</p>

          <services
                    :products="serviceItems"
                    :versionId="statementVersionId"
                    @reloadProducts="getStatementForOrderInfo"
                  ></services>
        </Card>

    <Card :bordered="false" dis-hover>
        <p slot="title">结算单附件</p>

        <fileUpload
    ref="fileuploadComponent"
     type="STATEMENT_ATTACHMENT_FILE"
      :existsFile="existsFile"
     :actionUrl="'/ooh-scp/v1/contractfile/uploadfile'"
     :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"
    :relateId="statementVersionId" :format="fileTypes"
     @on-removeFile="handleRemoveFile"
     ></fileUpload>

        <!-- <fileUpload type="" :relateId="statementVersionId" :format="fileTypes"  :actionUrl="'/ooh-scp/v1/contractfile/uploadfile'"
     :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"></fileUpload> -->
    </Card>
  </div>
</template>

<script>
// components
import payment from '@/components/statement/Payments'
// import estimate from '@/components/statement/Estimate'
import products from '@/components/statement/ProductsPriceEdit'
import services from '@/components/statement/ServicePriceEdit'
import fileUpload from '@/components/common/FileUploadV2'

// utils
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule, ParseDate } from '@/utils/dateFormat'

// api
import { deleteFiles, getFileList } from '@/api/scp/contractfile'
import { getOrder } from '@/api/order/order'
import { getOrderSettlementPrice } from '@/api/scp/statementorder'
// import { getorderplanprice } from '@/api/scp/statementorder'
import { getPayDiscountList } from '@/api/scp/paydiscount'
import { getVersionDetail, changeStatement } from '@/api/scp/statementChange'
import { judgeUseStoredContract } from '@/api/scp/storedContract'
import { listSignServiceItem } from '@/api/scp/contractorder'

export default {
  components: {
    payment,
    // estimate,
    products,
    services,
    fileUpload
  },
  props: {
    baseId: {
      type: Number,
      required: true
    },
    otherParams: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      statementVersionId: this.otherParams,
      statementId: this.baseId,
      statementDetail: {},
      orderId: 0,
      discounts: [],
      currentDiscountId: 0,
      order: {},
      EstimateService: [],
      normal: [],
      special: [],
      products: [],
      fileTypes: ['img', 'jpg', 'png', 'doc', 'docx', 'pdf'],
      existsFile: [],
      serviceItems: []
    }
  },
  created () {
    this.initPageData()
    this.getExistsFiles()
  },
  methods: {
    formatMoney (money) {
      return toMoney(money)
    },

    getExistsFiles () {
      const that = this
      const data = { type: 'STATEMENT_ATTACHMENT_FILE', relateId: this.statementVersionId }
      getFileList(data).then(res => {
        that.existsFile = res.map(file => {
          file.name = file.fileName
          return file
        })
      })
    },
    handleRemoveFile (file) {
      const id = JSON.stringify([file.id || file.response.id])
      deleteFiles({ fileIds: id }).then(res => {
        this.$refs.fileuploadComponent.removeSuccess(file)
      })
    },
    initPageData () {
      getVersionDetail({ versionId: this.statementVersionId }).then(res => {
        this.orderId = res.orderIdList[0]
        res.schedule = GetCurrentSchedule(res.startDate, res.endDate)
        this.statementDetail = res
        this.currentDiscountId = res.payDiscountId
        this.getStatementForOrderInfo()
      })
    },
    getStatementForOrderInfo () {
      getOrderSettlementPrice({ orderId: this.orderId, versionId: this.statementVersionId }).then(products => {
        this.products = products
        listSignServiceItem({
          orderId: this.orderId,
          versionId: this.statementVersionId
        }).then((res) => {
          this.serviceItems = res
          this.getDiscounts()
        })
      })

      getOrder({ orderId: this.orderId }).then(order => {
        this.order = order
        // this.order.schedule = GetCurrentSchedule(order.startDate, order.endDate)
      })
    },
    getDiscounts () {
      const publisher = this.$store.getters.token.userInfo.publisherId
      var discounts = []
      judgeUseStoredContract({ statementId: this.statementId }).then((res) => {
        if (res) {
          const item = { desc: '储值抵扣', paydiscountId: -99, type: 3, discount: 0, paydayproportions: [] }
          discounts.push(item)
        }
        getPayDiscountList({ publisherId: publisher, type: 1 }).then((data) => {
          data.forEach((x) => {
            let desc = ''
            x.paydayproportions
              .filter((x) => x.proportion > 0)
              .forEach((y, index) => {
                const header = index === 0 ? '刊前' : '刊后'
                desc +=
                  header +
                  Math.abs(y.day) +
                  '天付款' +
                  (y.proportion * 100).toFixed(2) +
                  '% ,'
              })
            desc += '媒介使用费打' + (x.discount * 10).toFixed(2) + '折'
            x.desc = desc
          })
          discounts = discounts.concat(data)
          this.discounts = discounts
          // this.discounts = this.discounts.concat(data)

          if (this.discounts.length && this.currentDiscountId === -1) {
            this.currentDiscountId = this.discounts[0].paydiscountId
          }
        })
      })
    },
    handleSubmit () {
      this.$Modal.confirm({
        title: '是否确认要提交该结算单变更？',
        content: '您确认提交该结算单变更后，将无法更改其中信息，该结算单变更即将进入审批通道',
        onOk: () => {
          this.submit(4)
        }
      })
    },
    submit (status) {
      const changeBean = {
        versionId: this.statementVersionId,
        // payDiscountId: this.currentDiscountId,
        payments: JSON.stringify(this.payItems.map(x => {
          return {
            date: ParseDate(x.date),
            amount: x.money
          }
        })),
        status: status
      }
      if (this.chkDiscount.paydiscountId > 0) {
        changeBean.paymentMethod = 1
        changeBean.payDiscountId = this.chkDiscount.paydiscountId
      } else {
        changeBean.paymentMethod = 2
      }
      changeStatement(changeBean).then(res => {
        this.$emit('on-submit')
      })
    },
    computeSchedule (products) {
      if (products.length > 0) {
        const product = products[0]
        return GetCurrentSchedule(product.startDate, product.endDate)
      }
    }
  },
  computed: {
    payItems: function () {
      let payItems = []

      this.chkDiscount.paydayproportions.forEach((item, index) => {
        payItems.push({
          date: Date.parse(this.statementDetail.endDate) + item.day * 1000 * 60 * 60 * 24,
          money: (this.usePrice * this.chkDiscount.discount * item.proportion).toFixed(2) * 1 + (index ? 0 : this.serviceMount)
        })
      })
      if (payItems.length) {
        payItems[0].date = Date.parse(this.statementDetail.startDate) + this.chkDiscount.paydayproportions[0].day * 1000 * 60 * 60 * 24
      }
      payItems = payItems.filter(x => x.money > 0)

      const length = payItems.length
      if (length > 0) {
        let payed = 0
        payItems.forEach(item => {
          payed += item.money
        })
        payItems[length - 1].money += (this.computedUsePrice + this.serviceMount - payed).toFixed(2) * 1
      }

      return payItems
    },
    chkDiscount: function () {
      if (this.discounts.length) {
        return this.discounts.find(x => x.paydiscountId === this.currentDiscountId)
      } else {
        return { discount: 1, paydayproportions: [{ day: 0, proportion: 1 }] }
      }
    },
    computedUsePrice: function () {
      return this.usePrice * this.chkDiscount.discount
    },
    serviceMount: function () {
      let amount = 0
      if (this.serviceItems && this.serviceItems.length) {
        this.serviceItems.forEach((product) => {
          amount += product.signServiceAmount
        })
      }

      return amount
    },
    usePrice: function () {
      let total = 0
      this.products.forEach((product) => {
        total += product.normalSettlementPrice // + product.totalInstallSettlePrice + product.totalProduceSettlePrice
      })
      return total
    }
  }
}
</script>
